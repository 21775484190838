<template>
  <el-dialog
    title="添加分子条目"
    :visible="dialogAddFormVisible"
    width="70rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="分子名称"
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              v-model="form.name"
              placeholder="请输入分子名称"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="分子式"
            :label-width="formLabelWidth"
            prop="molecular_formula"
          >
            <el-input
              v-model="form.molecular_formula"
              placeholder="请输入分子式"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="CAS号码"
            :label-width="formLabelWidth"
            prop="cas_number"
          >
            <el-input
              v-model="form.cas_number"
              placeholder="请输入CAS号码"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="CID"
            :label-width="formLabelWidth"
            prop="pub_chem_cid"
          >
            <el-input
              v-model="form.pub_chem_cid"
              placeholder="请输入CID"
            ></el-input> </el-form-item></el-col
      ></el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAddCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleAdd()"
        icon="el-icon-circle-check"
        >创 建</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "MoleculeInfoAdd",
  data() {
    return {
      dialogAddFormVisible: false,
      isLoading: false,
      formLabelWidth: "5rem",
      form: {
        name: "",
        molecular_formula: "",
        cas_number: "",
        pub_chem_cid: "",
      },
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen() {
      this.dialogAddFormVisible = true;
    },
    handleAdd() {
      this.isLoading = true;
      this.$store
        .dispatch("moleculeInfo/add", this.form)
        .then((res) => {
          this.$message.success(res.msg);
          this.handleAddCancel();
          this.$emit("getInfo");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    handleAddCancel() {
      this.form = {};
      this.dialogAddFormVisible = false;
    },
  },
};
</script>

<style>
</style>