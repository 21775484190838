<template>
  <el-card class="info_card">
    <div slot="header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="20"><span>分子条目管理</span></el-col
        ><el-col :span="1.5">
          <el-button
            type="success"
            round
            icon="el-icon-circle-plus-outline"
            @click="Add()"
            :loading="isLoading"
            >添加</el-button
          ></el-col
        ></el-row
      >
    </div>
    <MoleculeInfoSelect ref="MoleculeInfoSelect" />
    <MoleculeInfoAdd @getInfo="getInfo" ref="MoleculeInfoAdd" />
  </el-card>
</template>

<script>
import MoleculeInfoSelect from "./MoleculeInfoSelect/index.vue";
import MoleculeInfoAdd from "./MoleculeInfoAdd/index.vue";
export default {
  components: { MoleculeInfoSelect, MoleculeInfoAdd },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    Add() {
      this.$refs.MoleculeInfoAdd.handleOpen()
    },
    getInfo() {
      this.$refs.MoleculeInfoSelect.getInfo();
    },
  },
};
</script>

<style scoped>
</style>