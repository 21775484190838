<template>
  <div>
    <el-table :data="infoList" border style="width: 100%">
      <el-table-column prop="name" label="分子名称" width="150">
      </el-table-column>
      <el-table-column prop="molecular_formula" label="分子式" width="150">
      </el-table-column>
      <el-table-column prop="cas_number" label="CAS号码"></el-table-column>
      <el-table-column prop="pub_chem_cid" label="CID"></el-table-column>
      <el-table-column prop="create_time" width="120" label="创建时间">
        <template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.create_time) }}</span>
        </template></el-table-column
      >
      <el-table-column prop="update_time"  width="120" label="最近更新时间"
        ><template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_user_name" width="100" label="创建用户">
      </el-table-column>
      <el-table-column prop="status" width="100" label="条目状态"
        ><template slot-scope="scope">
          <el-tag
            :type="
              scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'
            "
            >{{
              scope.row.status == 1
                ? "待审核"
                : scope.row.status == 2
                ? "已通过"
                : scope.row.status == 3
                ? "未通过"
                : "已下线"
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.status == 1 || scope.row.status == 3"
            type="primary"
            @click="handleAudit(scope.$index, scope.row)"
            >审核</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <MoleculeInfoEdit :form="form" ref="MoleculeInfoEdit" @getInfo="getInfo" />
    <MoleculeInfoAudit
      :form="form"
      ref="MoleculeInfoAudit"
      @getInfo="getInfo"
    />
  </div>
</template>
  
  <script>
import MoleculeInfoEdit from "../MoleculeInfoEdit/index.vue";
import MoleculeInfoAudit from "../MoleculeInfoAudit/index.vue";
export default {
  name: "MoleculeInfoSelect",
  components: {
    MoleculeInfoEdit,
    MoleculeInfoAudit,
  },
  data() {
    return {
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      form: {},
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("moleculeInfo/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //编辑列函数
    handleEdit(index, row) {
      this.form = row;
      this.$refs.MoleculeInfoEdit.handleOpen(this.form);
    },
    //审核函数
    handleAudit(index, row) {
      this.form = row;
      this.$refs.MoleculeInfoAudit.handleOpen(this.form);
    },
    //删除列函数
    handleDelete(index, row) {
      this.$confirm(
        "此操作将删除：" +
          row.name +
          "(" +
          row.molecular_formula +
          ") 相关信息 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          // this.$store.dispatch("user/del", { id: row.id }).then((res) => {
          //   this.$message.success(res.msg);
          //   this.getInfo();
          // });
          this.$message.info("该功能暂未实现");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
  
  <style>
</style>