<template>
  <el-dialog
    title="审核分子条目"
    :visible="dialogAuditFormVisible"
    width="70rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-descriptions title="分子基本信息" :column="2" border>
      <el-descriptions-item label="分子名称">{{
        form.name
      }}</el-descriptions-item>
      <el-descriptions-item label="分子式">{{
        form.molecular_formula
      }}</el-descriptions-item>
      <el-descriptions-item label="CAS号码"
        ><el-tag type="success" effect="plain">{{
          form.cas_number == "" ? "暂无" : form.cas_number
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="CID号码"
        ><el-tag type="success" effect="plain">{{
          form.pub_chem_cid == "" ? "暂无" : form.pub_chem_cid
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="创建时间">
        <el-tag type="warning">{{
          timestampToTime(form.create_time)
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="最近更新时间"
        ><el-tag type="warning">{{
          form.update_time
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="创建用户"
        ><el-tag type="danger">{{
          form.create_user_name
        }}</el-tag></el-descriptions-item
      >
    </el-descriptions>
    <el-card class="propertie_card">
      <div slot="header">
        <el-row type="flex" justify="space-between"
          ><el-col :span="19"><b>分子性质</b></el-col
          ><el-col :span="4.5"> </el-col
        ></el-row>
      </div>
      <div v-html="content" class="propertie_pannel"></div
    ></el-card>
    <el-row type="flex" :gutter="10" class="file_references_row"
      ><el-col>
        <el-card
          ><div slot="header"><b>分子相关文件</b></div>
          <el-row type="flex" class="file_pannel"
            ><el-col :span=12
              ><span>分子结构文件</span>
              <el-upload
                action=""
                disabled
                :on-preview="handlePreview"
                ref="upload"
                :limit="1"
                :file-list="structFileList"
              >
                
              </el-upload></el-col
            ><el-col :span=12
              ><span>分子计算结果文件（from Gaussian）</span
              ><el-upload
                action=""
                disabled
                :on-preview="handlePreview"
                ref="upload"
                :limit="1"
                :file-list="calculationResultFileList"
              >
               
              </el-upload></el-col
            ></el-row
          ></el-card
        ><el-progress
          :percentage="percentage"
          v-if="isShowPercentage"
        ></el-progress></el-col
      ><el-col>
        <el-card
          ><div slot="header">
            <el-row type="flex" justify="space-between" :gutter="10">
              <el-col :span="4"><b>参考文献</b></el-col>
              <el-col :span="16"></el-col>
            </el-row>
          </div>
          <ul v-if="references.length != 0" class="references_pannel">
            <li v-for="(item, index) in references" :key="index">
              <el-card shadow="hover" class="li_card">
                <el-row type="flex" justify="space-between"
                  ><el-col :span="24"
                    ><span>{{ item.content }}</span></el-col
                  ></el-row
                >
              </el-card>
            </li>
          </ul>
          <span v-else>暂无信息</span>
        </el-card>
      </el-col></el-row
    >
    <el-row class="remark_row">
      <el-card>
        <div slot="header"><b>审核意见</b></div>
        <el-input
          type="textarea"
          rows="3"
          placeholder="请输入审核意见"
          v-model="remark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </el-card>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAuditCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="danger"
        :loading="isLoading"
        @click="handleAudit(3)"
        icon="el-icon-remove-outline"
        >驳回</el-button
      >
      <el-button
        type="success"
        :loading="isLoading"
        @click="handleAudit(2)"
        icon="el-icon-circle-check"
        >通过</el-button
      >
    </div>
  </el-dialog>
</template>
  
  <script>
import axios from "axios";
export default {
  name: "MoleculeInfoAudit",
  data() {
    return {
      //下载文件进度条所需参数
      percentage: 0, //进度条的占比
      isShowPercentage: false, //是否显示进度条
      dialogAuditFormVisible: false,
      isLoading: false,
      formLabelWidth: "5rem",
      headerObj: {
        Authorization: " Bearer " + this.$store.state.tokenObj.token,
      },
      //审核意见
      remark: "",
      //分子性质内容
      content: "",
      //分子结构文件相关参数
      structFileList: [],
      //分子计算结果文件相关参数
      calculationResultFileList: [],
      //参考文献列表
      references: [],
      form: {},
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    selectFile() {
      this.$store
        .dispatch("moleculeInfo/selectFile", {
          id: this.form.id,
        })
        .then((res) => {
          const { data } = res;
          if (data.struct_file) {
            this.structFileList.push(data.struct_file);
          }
          if (data.calculation_result_file) {
            this.calculationResultFileList.push(data.calculation_result_file);
          }
        });
    },
    //获取分子性质记录
    getPropertieRecord() {
      this.$store
        .dispatch("propertieRecord/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          if (res.data != undefined && res.data != null) {
            this.content = res.data.content;
          } else {
            this.content = "";
          }
        });
    },
    //获取参考文献列表
    getReferences() {
      this.$store
        .dispatch("references/select", {
          molecule_info_id: this.form.id,
        })
        .then((res) => {
          var { data } = res;
          this.references = [];
          data.forEach((item) => {
            this.references.push(item);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleOpen(data) {
      //初始化下载进度条参数
      this.percentage = 0;
      this.dialogAuditFormVisible = true;
      this.form = data;
      this.selectFile();
      this.getPropertieRecord();
      this.getReferences();
    },
    //保存审核的信息
    handleAudit(status) {
      var statusText = status == 2 ? "通过" : "驳回";
      this.$confirm("确认" + statusText + "？")
        .then((_) => {
          this.$store
            .dispatch("moleculeInfoAuditRecord/add", {
              molecule_info_id: this.form.id,
              status: status,
              remark: this.remark,
            })
            .then((res) => {
              this.$emit("getInfo");
              this.$message.success(res.msg);
              this.handleAuditCancel();
            })
            .catch(() => {
              this.$message.error("保存失败！");
            });
        })
        .catch((_) => {});
    },
    handleAuditCancel() {
      this.dialogAuditFormVisible = false;
      this.structFileList = [];
      this.calculationResultFileList = [];
      this.references = [];
      this.remark = "";
      this.isShowPercentage = false;
    },
    handlePreview(file) {
      //通过访问minio的文件链接获取文件
      //不添加token的请求方式--start
      // const getFileUrlInstance = axios.create({
      //   baseURL: process.env.VUE_APP_API_URL + "/file/download/",
      // });
      //不添加token的请求方式--end
      axios({
        method: "get",
        url: process.env.VUE_APP_API_URL + "/file/download/" + file.url,
      }).then((res) => {
        // 获取文件的访问链接
        const { file_url } = res.data;
        this.$message.success("正在下载文件......");
        this.isShowPercentage = true;
        //初始化下载进度条参数
        this.percentage = 0;
        const getFileInstance = axios.create({
          baseURL: file_url,
        });
        getFileInstance({
          timeout: 0, // 不设置超时时间
          method: "get",
          responseType: "blob",
          onDownloadProgress: (progress) => {
            this.percentage = progress.progress * 100;
          },
        })
          .then((res) => {
            this.downloadFile(res.data, file.name);
          })
          .catch((error) => {
            this.$message.error("文件下载失败！请联系管理员");
            this.isShowPercentage = false;
            console.log(error);
          });
      });
    },
    downloadFile: function (data, fileName) {
      this.$message.success("下载完成！正在编译文件......");
      // data为blob格式
      var blob = new Blob([data]);
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
  
  <style scoped>
.propertie_pannel {
  height: 15rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
.li_card ::v-deep .el-card__body {
  padding: 10px !important;
}
.propertie_card::v-deep .el-card__body {
  padding: 10px !important;
}
.file_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.references_pannel {
  height: 7.5rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.file_references_row {
  margin: 1rem 0;
}
</style>